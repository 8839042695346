@import "../settings";

.testimonial {
	padding: ($global-padding * 5) 0;

	@include breakpoint(medium) {
		padding: ($global-padding * 7) 0;
	}

	&__grid {
		@include mon-cols(8);
	}

	&__card {
		text-align: center;
	}

	&__quote {
		font-size: rem-calc(38);
		font-weight: $global-weight-bold;
		letter-spacing: -1px;
		line-height: 110%;
		margin-bottom: $global-margin * 2;
		text-align: center;
        padding: 0 ($global-padding * 2.5);

		@include breakpoint(medium) {
		    font-size: rem-calc(58);
            padding: 0;
		}
	}

	&__cite {
		font-size: rem-calc(22);
		position: relative;

		@include breakpoint(medium) {
			font-size: rem-calc(24);
		}
	}
}
